import React, { useState } from 'react';
import { IoIosCloseCircle } from "react-icons/io";
import { CiFacebook, CiInstagram } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";

import {
    Container,
    Top,
    New,
    Description,
    SolusChat,
    Tag,
    More,
    Modal,
    Content
} from './styles';

interface NewsProps { }

export function News({ }: NewsProps) {
    const [visible, setVisible] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setVisible(false);
    };

    if (!visible) return null;

    const titleWithSpan = (
        <span dangerouslySetInnerHTML={{
            __html: `
Temos o prazer de anunciar que o Soluschat agora possui integração com o Facebook e o Instagram! Isso significa que você pode realizar atendimentos diretamente por essas plataformas, tornando a comunicação com seus clientes ainda mais fácil e eficiente.

Com essa novidade, você poderá:

 - Centralizar o atendimento: Gerencie todas as mensagens recebidas pelo Facebook Messenger e Instagram Direct em um só lugar.
 - Melhorar a eficiência: Responda rapidamente às solicitações dos clientes sem precisar alternar entre diferentes aplicativos.
 - Aumentar a satisfação do cliente: Ofereça suporte onde seus clientes já estão, proporcionando uma experiência mais conveniente para eles.
Estamos empolgados com essa integração e acreditamos que ela contribuirá para aprimorar ainda mais o relacionamento com seus clientes.

Aproveite essa oportunidade para expandir seus canais de atendimento, contate nosso comercial!` }} />
    );

    return (
        <Container>
            <Top>
                <New>Novidade</New>
                <IoIosCloseCircle onClick={handleClose} />
            </Top>
            <Description>
                <SolusChat>SolusChat</SolusChat> agora tem integração com <Tag style={{ backgroundColor: 'var(--instagram)' }}><CiInstagram /> Instagram</Tag>
                e <Tag style={{ backgroundColor: 'var(--facebook)' }}><CiFacebook /> Facebook</Tag>
            </Description>

            <More>
                <span onClick={() => setShowModal(true)}>Ler mais <MdKeyboardArrowDown /></span>
            </More>

            {showModal &&
                <Modal>
                    <Content>
                        <IoIosCloseCircle onClick={() => setShowModal(false)} />
                        {titleWithSpan}
                    </Content>
                </Modal>
            }
        </Container>
    );
}
