import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  gap: 2rem;
  overflow: auto;
  padding: 1rem;
  margin: 0 auto;
  flex-direction: column;
  position: relative;

  h1 {
    text-align: center;
    margin-top: 2rem;
  }
`;

export const Embed = styled.object`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    padding: 1rem;
    border-radius: 0.5rem;
    background: var(--primarySolusChat);
    color: var(--darkSolusChat);
    text-decoration: none;
    width: fit-content;
  }
`;

export const Site = styled(Link)`
  display: flex;
  position: absolute;
  align-items: center;
  gap: 0.5rem;
  width: fit-content;
  text-decoration: none;
  color: var(--primarySolusChat);
  top:1rem;
  right: 2rem;

`;