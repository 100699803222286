import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Slide = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 7rem;
  }

  .soluschat {
    height: 2.5rem;
  }

  @keyframes slide {
    0% {
      transform: translateX(-10%);
    }
    100% {
      transform: translateX(-110%);
    }
  }

  animation: 25s slide infinite linear;

  .buddy {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 1));
  }
`;
