import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { Link } from 'react-scroll';

import Logo from '../../../../assets/logo.png';

import {
  Container,
  Content,
  LogoContainer,
  LinksContainer,
  RightsContainer,
  ContentRights
} from './styles'

export function FooterSolusChat() {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <LinkRouter to={'https://buddysoftware.com.br/'}>
            <img src={Logo} alt="logo" />
          </LinkRouter>
          <span>Simples e confiável.</span>
        </LogoContainer>
        <LinksContainer>
          <ul>
            <li>
              <Link smooth spy to="homesoluschat">
                Início
              </Link>
            </li>
            <li>
              <Link smooth spy to="soluschat">
                SolusChat
              </Link>
            </li>
            <li>
              <Link smooth spy to="functionalitysoluschat">
                Funcionalidades
              </Link>
            </li>
            <li>
              <Link smooth spy to="pricessoluschat">
                Preços
              </Link>
            </li>
            <li>
              <Link smooth spy to="helpsoluschat">
                Ajuda
              </Link>
            </li>
          </ul>
        </LinksContainer>
      </Content>
      <RightsContainer>
        <ContentRights>
          <div>
            <p>© 2024 Buddy Software Solutions LTDA | CNPJ: 51.784.992/0001-26</p>
          </div>
          <div>
            <p>
              <LinkRouter to="/terms-of-use">Termos de Uso</LinkRouter> | <LinkRouter to="/privacy-policy">Política de Privacidade</LinkRouter>
            </p>
          </div>
        </ContentRights>
      </RightsContainer>
    </Container>
  );
}