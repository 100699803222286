import React, { useState } from 'react';

import { FaCheck } from "react-icons/fa6";
import { IoMdArrowRoundForward } from "react-icons/io";

import {
  Container,
  Title,
  Img,
  Price,
  Invoicing,
  ItemsContainer,
  Items,
  ItemsTitle,
  Item,
  Resume,
  PrinceButton,
  Popular
} from './styles'
import { PriceModalContact } from '../PriceModalContact';

interface PriceContainerProps {
  type: string;
  img: string;
  price: string;
  invoicing: 'mês' | 'ano',
  link: string;
  itens: Array<{
    id: number;
    included: 'included' | 'notIncluded';
    item: string;
  }>;
  popular: boolean;
}

export function PriceContainer({ type, img, price, invoicing, itens, popular }: PriceContainerProps) {
  const [showModal, setShowModal] = useState(false);

  if (showModal) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  function handleModal() {
    setShowModal(!showModal);
  }

  return (
    <>
      <Container>
        <Title>{type}</Title>
        <Img src={img} alt={type} />
        <Price>R$ <span>{price}</span> /{invoicing}</Price>
        <Invoicing>Faturamento {invoicing === 'mês' ? 'mensal' : 'anual'}</Invoicing>
        <ItemsContainer>
          <ItemsTitle>Incluso:</ItemsTitle>
          <Items>
            {itens.map((item) =>
              <Item key={item.id}>
                <FaCheck className={item.included} />
                <Resume>{item.item}</Resume>
              </Item>
            )}
          </Items>
        </ItemsContainer>
        <PrinceButton onClick={() => handleModal()}>
          Contratar < IoMdArrowRoundForward />
        </PrinceButton>

        {popular &&
          <Popular>
            Popular
          </Popular>
        }
      </Container >
      {showModal &&
        <PriceModalContact onClose={handleModal} plano={type} itens={itens} price={price} />
      }
    </>
  );
}