import styled from 'styled-components';
import { motion } from 'framer-motion';

import CardBackground from '../../../assets/solusChat/card.png';

export const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 24rem;
  height: fit-content;
  gap: 1rem;
  color: var(--darkSolusChat);

  background-image: url(${CardBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  padding: 4rem 1rem;
  border-radius: 2rem;
  filter: drop-shadow(5px 5px 0px var(--primarySolusChat));
  z-index: 1;

  img {
    height: 10rem;
    top: -5rem;
    position: absolute;
  }

  span {
    text-align: center;
    font-size: 1rem;
    color: #ffffff;
  }

  h2 {
    margin-top: 2rem;
    text-align: center;
    color: #ffffff;
  }
  transition: scale 0.5s ease;

  &:hover {
    scale: 1.05;
  }
`;
