import React, { createContext, useEffect, useState } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { Analytics } from '@vercel/analytics/react';

import { RouterPage } from './routes/index';

import { DarkTheme, GlobalStyles, LightTheme } from './styles/global';
import { LoadingPage } from './pages/LoadingPage';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

export const ThemeContext = createContext({} as any);

function App() {
  const [theme, setTheme] = useState('light');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    function onPageLoad() {
      setIsLoading(false);
    }

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => {
        window.removeEventListener('load', onPageLoad);
      };
    }
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <StyledComponentsThemeProvider
        theme={theme === 'dark' ? DarkTheme : LightTheme}
      >
        <GlobalStyles />
        <Analytics />
        <ToastContainer theme='dark' />
        {isLoading ? <LoadingPage /> : <RouterPage />}
      </StyledComponentsThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
