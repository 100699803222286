import React, {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import { Page404 } from '../pages/Page404';
import { LandingPageSolusChat } from '../pages/LandingPageSolusChat';
import { PropostaComercial } from '../pages/PropostaComercial';
import TermsOfUse from '../pages/terms-of-use';
import PrivacyPolicy from '../pages/privacy-policy';

export function RouterPage() {
  return (
    <Router>
      <Routes>
        <Route index element={<LandingPageSolusChat />} path='/' />
        <Route element={<Page404 />} path='*' />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route element={<PropostaComercial />} path='/propostacomercial' />
      </Routes>
    </Router>
  );
}
