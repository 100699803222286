interface HomeProps {
  title: string;
  description: string;
  buttonText: string;
}

interface SummaryProps {
  title: string;
  description: string;
}

interface FuncionalityProps {
  title: string;
  description: string;
}

interface PriceProps {
  title: string;
  subTitle: string;
  description: string;
}

interface FaqProps {
  title: string;
  description: string;
}

export const Home: HomeProps = {
  title: 'Garanta a satisfação',
  description: 'dos seus clientes com atendimentos ágeis. Respostas instantâneas estão ao seu alcance com o <span>SolusChat</span>',
  buttonText: 'Contato'
};

export const Summary: SummaryProps = {
  title: 'Descubra o <span>SolusChat</span> da Buddy Software',
  description: `
  Transforme seu atendimento ao cliente com o SolusChat, a solução definitiva para gerenciamento de mensagens no WhatsApp. Diga adeus à necessidade de múltiplos celulares e centralize seu serviço de atendimento em um único número, maximizando eficiência e reduzindo custos.
  Com o SolusChat, você desfruta de um poderoso sistema equipado com inteligência artificial para atendimento automatizado, capaz de proporcionar experiências personalizadas e envolventes para seus clientes. Otimize a organização com nosso avançado gerenciamento de filas e agende mensagens com facilidade, garantindo que a comunicação seja sempre oportuna e relevante.
  Além disso, explore funcionalidades inovadoras como fluxos segmentados por setores, campanhas direcionadas e integrações flexíveis com inúmeros sistemas, tudo desenhado para elevar seu negócio a novos patamares. O SolusChat é a ferramenta ideal para revolucionar sua gestão de atendimento e impulsionar o crescimento da sua empresa.`
};

export const Funcionality: FuncionalityProps = {
  title: 'Funcionalidades',
  description: 'Adicione mais funcionalidades ao seu whatsapp'
};

export const Price: PriceProps = {
  title: 'Selecione seu plano',
  subTitle: 'Precisa de Mais? Fale Conosco!',
  description: `Se você está buscando algo ainda mais personalizado ou tem necessidades específicas que vão além dos nossos planos padrão, estamos aqui para ajudar! Seja uma configuração especial, funcionalidades adicionais ou qualquer outro ajuste, nosso time está pronto para criar uma solução sob medida para o seu negócio.

  Não hesite em nos contatar diretamente pelo WhatsApp. Estamos à disposição para garantir que o SolusChat atenda perfeitamente às suas expectativas, ajudando você a alcançar os melhores resultados possíveis. Vamos juntos impulsionar o seu atendimento ao cliente para o próximo nível!

  <br /> Clique <a href="https://api.whatsapp.com/send?phone=5548988791489&text=Ol%C3%A1,%20tenho%20uma%20dúvida%20sobre%20o%20SolusChat%20" target="_blank" rel="noreferrer">aqui</a> para nos chamar no WhatsApp e vamos personalizar seu plano juntos!`
};

export const Faq: FaqProps = {
  title: 'Dúvidas frequentes',
  description: 'Caso não encontre sua dúvida aqui, entre em contato com o nosso suporte.'
};
