import React from 'react';

import {
  Container,
  Content,
  Title,
  PriceGrid,
  FooterMessage,
  FooterMessageDescription
} from './styles'

import { PriceContainer } from '../../../SolusChat/PriceContainer';

import { prices } from '../../../../utils/prices';
import { Price as PriceData } from '../../../../utils/texts';

export function Price() {
  const descriptionWithSpan = (
    <span dangerouslySetInnerHTML={{ __html: PriceData.description }} />
  );

  return (
    <Container id='pricessoluschat'>
      <Content>
        <Title>{PriceData.title}</Title>
        <PriceGrid>
          {prices.map(item =>
            <PriceContainer key={item.type} type={item.type} img={item.img} price={item.price} invoicing={item.invoicing} itens={item.itens} popular={item.popular} link={item.link} />
          )}
        </PriceGrid>
        <FooterMessage>{PriceData.subTitle}</FooterMessage>
        <FooterMessageDescription>{descriptionWithSpan}</FooterMessageDescription>
      </Content>
    </Container>
  );
}