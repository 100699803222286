import React from 'react';

import { functionality } from '../../../../utils/functionality';

import { Container, Content, Title, Description, GridContainer } from './styles';
import { FunctionalityContainer } from '../../FunctionalityContainer';

import { Funcionality } from '../../../../utils/texts';

export function FunctionalitySolusChat() {
  return (
    <Container id='functionalitysoluschat'>
      <Content>
        <Title>{Funcionality.title}</Title>
        <Description>{Funcionality.description}
        </Description>
        <GridContainer>
          {functionality.map(item =>
            <FunctionalityContainer key={item.id} title={item.title} description={item.description} img={item.img} />
          )}
        </GridContainer>
      </Content>
    </Container>
  );
};
