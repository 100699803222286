import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.section`
  margin: 0 auto;
  max-width: 1400px;
  display: flex;
  min-height: 100vh;
  padding: 0 2rem;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-top: 5.5rem;
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
`;

export const PriceGrid = styled.div`
  display: flex;
  gap: 4rem 5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
`;

export const FooterMessage = styled.span`
  margin-top: 0.5rem;
  font-size: 1.1rem;
  text-align: center;
`;

export const FooterMessageDescription = styled.div`
  text-align: center;

  span {
    font-size: 1rem;

    a {
      text-decoration: none;
      color: var(--primarySolusChat)
    }
  }
`;
