import React, { useContext } from 'react';

import People from '../../../../assets/people.svg'

import { Container, Content, Gradient, Background, TextContainer, Border, Title, Description, ImgContainer } from './styles';
import { Link } from 'react-scroll';
import { Button } from '../../../Button';
import { FiArrowDownRight } from 'react-icons/fi';

import { Home as HomeData } from '../../../../utils/texts';

import { ThemeContext } from '../../../../App';

export function HomeSolusChat() {
  const { theme } = useContext(ThemeContext);

  const descriptionWithSpan = (
    <h3 dangerouslySetInnerHTML={{ __html: HomeData.description }} />
  );

  return (
    <Container id='homesoluschat'
      initial={{ translateY: -100, opacity: 0 }}
      whileInView={{ translateY: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{
        duration: 0.3
      }}
    >
      <Background />
      <Gradient top theme={theme} />
      <Content>
        <TextContainer>
          <Border />
          <Title>{HomeData.title}</Title>
          <Description>{descriptionWithSpan}</Description>
          <Link smooth spy to="helpsoluschat">
            <Button icon={<FiArrowDownRight />} site='SolusChat'>{HomeData.buttonText}</Button>
          </Link>
        </TextContainer>
        <ImgContainer>
          <img src={People} alt="Phone" />
        </ImgContainer>
      </Content>
      <Gradient top={false} theme={theme} />
    </Container>
  );
};
