import React, { useContext } from 'react';

import { Container, Content, Gradient, Background, TextContainer, Title, Description, DescriptionContainer } from './styles';

import { Summary as SummaryData } from '../../../../utils/texts';

import { ThemeContext } from '../../../../App';

export function SummarySolusChat() {
  const { theme } = useContext(ThemeContext);

  const titleWithSpan = (
    <h1 dangerouslySetInnerHTML={{ __html: SummaryData.title }} />
  );

  return (
    <Container id='soluschat'>
      <Background />
      <Gradient top theme={theme} />
      <Content>
        <TextContainer>
          <Title>{titleWithSpan}</Title>
        </TextContainer>
        <DescriptionContainer>
          <Description>
            {SummaryData.description}
          </Description>
        </DescriptionContainer>
      </Content>
      <Gradient top={false} theme={theme} />
    </Container>
  );
};
