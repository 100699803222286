import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`;

export const Content = styled.div`
  background: var(--darkSolusChat);
  color: var(--lightSolusChat);
  padding: 2rem;
  border-radius: 0.5rem;
  width: 30rem;
  filter: drop-shadow(5px 5px 0px var(--primarySolusChat));
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;

  span {
    cursor: pointer;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Input = styled.input`
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: var(--darkLightSolusChat);
  color: #ffffff;
  border: 1px solid gray;
`;

export const Button = styled.button`
  background: var(--primarySolusChat);
  color: var(--darkSolusChat);
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: bold;
  width: 10rem;
  margin: 0 auto;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background: gray;
    cursor: not-allowed;
    &:hover {
      filter: none;
    }
  }

  &:not(:disabled):hover {
    filter: brightness(0.8);
  }
`;

export const Label = styled.label`
  font-size: 0.9rem;

  span {
    color: var(--primarySolusChat);
  }
`;

export const Selected = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  font-size: 0.9rem;
  flex-direction: column;
  align-items: center;

  p {
    font-weight: bold;
    display: flex;
    width: 100%;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    background: var(--primarySolusChat);
    border-radius: 0.2rem;
    font-size: 2rem;
    color: var(--darkSolusChat);
  }
`;

export const Select = styled.div`
  display: flex;
  gap: 0.5rem;
  position: relative;

  select {
    width: 5rem; 
    padding: 0 1rem;
    font-size: 1rem; 
    border: none;
    border-radius: 0.5rem; 
    background-color: var(--darkLightSolusChat);
    border: 1px solid gray;
    color: #ffffff;
    appearance: none;
    -webkit-appearance: none; 
    -moz-appearance: none;
    cursor: pointer;
    font-family: 'Comfortaa', cursive;

  }
  
  .arrow {
    position: absolute;
    font-size: 1.5rem;
    left: 2.5rem;
    top: 0.4rem;
    color: var(--lightSolusChat);
    pointer-events: none;
  }
`;

export const SuccessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  p {
    text-align: center;
    line-height: 1.8rem;
  }

  a {
    color: var(--primarySolusChat);
    font-weight: bold;
    text-decoration: none;
  }

  .success {
    color: var(--primarySolusChat);
    font-size: 4rem;
  }
`;