import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-scroll';

import { MdOutlineMenu, MdOutlineClose } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";
import { CiLight } from "react-icons/ci";
import { MdOutlineDarkMode } from "react-icons/md";

import LogoImg from '../../../assets/solusChat/SolusChatLogoLongo.svg';
import LogoImgDark from '../../../assets/solusChat/SolusChatLogoLongoDark.svg';

import { Container, Content, Logo, Nav, NavMobile } from './styles';

import { ThemeContext } from '../../../App';

export function HeaderSolusChat() {
  const [scrolling, setScrolling] = useState(false);
  const [opened, setOpened] = useState(false);
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container scrolling={scrolling}>
      <Content>
        <Logo scrolling={scrolling}>
          <Link smooth spy to="homesoluschat">
            <img src={theme == 'dark' ? LogoImgDark : LogoImg} alt="logo" />
          </Link>
        </Logo>
        <Nav>
          <ul>
            <li>
              <Link smooth spy to="homesoluschat" activeClass="active">
                Início
              </Link>
            </li>
            <li>
              <Link smooth spy to="soluschat" activeClass="active">
                SolusChat
              </Link>
            </li>
            <li>
              <Link smooth spy to="functionalitysoluschat" activeClass="active">
                Funcionalidades
              </Link>
            </li>
            <li>
              <Link smooth spy to="pricessoluschat" activeClass="active">
                Preços
              </Link>
            </li>
            <li>
              <Link smooth spy to="helpsoluschat" activeClass="active">
                Ajuda
              </Link>
            </li>
            <li>
              <a href="https://app.soluschat.com.br/" target="_blank" rel="noreferrer" className='sistema'>
                Acessar Sistema
              </a>
            </li>
            <li>
              {
                theme == 'dark' ? <MdOutlineDarkMode onClick={() => setTheme('light')} /> : <CiLight onClick={() => setTheme('dark')} />
              }
            </li>
          </ul>
        </Nav>
        {
          opened ? <MdOutlineClose className='openIcon' onClick={() => { setOpened(!opened); }} /> :
            <MdOutlineMenu className='openIcon' onClick={() => { setOpened(!opened); }} />
        }
      </Content>
      <NavMobile opened={opened}>
        <ul>
          <li>
            <Link smooth spy to="homesoluschat" activeClass="active" onClick={() => { setOpened(!opened); }}>
              Início
            </Link>
          </li>
          <li>
            <Link smooth spy to="soluschat" activeClass="active" onClick={() => { setOpened(!opened); }}>
              SolusChat
            </Link>
          </li>
          <li>
            <Link smooth spy to="functionalitysoluschat" activeClass="active" onClick={() => { setOpened(!opened); }}>
              Funcionalidades
            </Link>
          </li>
          <li>
            <Link smooth spy to="pricessoluschat" activeClass="active" onClick={() => { setOpened(!opened); }}>
              Preços
            </Link>
          </li>
          <li>
            <Link smooth spy to="helpsoluschat" activeClass="active" onClick={() => { setOpened(!opened); }}>
              Ajuda
            </Link>
          </li>
          <li>
            <a href="https://app.soluschat.com.br/" target="_blank" rel="noreferrer" className='sistema'>
              Acessar Sistema
            </a>
          </li>
          <li>
            {
              theme == 'dark' ? <MdOutlineDarkMode onClick={() => setTheme('light')} /> : <CiLight onClick={() => setTheme('dark')} />
            }
          </li>
          <li>
            <IoIosArrowUp onClick={() => { setOpened(!opened); }} className='up' />
          </li>
        </ul>
      </NavMobile>
    </Container>
  );
}
