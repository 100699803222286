import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h1 style={styles.heading}>Política de Privacidade da Buddy Software</h1>
                <p style={styles.paragraph}>
                    Bem-vindo ao <a href="https://buddysoftware.com.br/" style={styles.link}>https://buddysoftware.com.br/</a>. Nós entendemos que a
                    privacidade online é importante para os usuários do nosso Site, especialmente ao realizar transações comerciais. Esta
                    declaração rege nossas políticas de privacidade em relação aos usuários do Site (Visitantes) que acessam sem realizar
                    transações e Visitantes que se registram para realizar transações no Site, utilizando os diversos serviços oferecidos
                    por este aplicativo (coletivamente, Serviços) (Clientes Autorizados).
                </p>
                <h2 style={styles.subheading}>Informações Pessoais Identificáveis</h2>
                <p style={styles.paragraph}>
                    Referem-se a qualquer informação que identifica ou pode ser usada para identificar, entrar em contato ou localizar a
                    pessoa a quem tal informação pertence, incluindo, mas não se limitando a, nome, endereço, número de telefone, número de
                    fax, endereço de e-mail, perfis financeiros, número de segurança social e informações de cartão de crédito. Informações
                    Pessoais Identificáveis não incluem informações coletadas anonimamente (ou seja, sem identificação do usuário
                    individual) ou informações demográficas que não estão conectadas a um indivíduo identificado.
                </p>
                <h2 style={styles.subheading}>Quais Informações Pessoais Identificáveis são coletadas?</h2>
                <p style={styles.paragraph}>
                    Podemos coletar informações básicas de perfil de usuário de todos os nossos Visitantes. Coletamos as seguintes
                    informações adicionais de nossos Clientes Autorizados: nomes, endereços, números de telefone e endereços de e-mail dos
                    Clientes Autorizados, a natureza e o tamanho do negócio, e a natureza e o tamanho do inventário de publicidade que o
                    Cliente Autorizado pretende comprar ou vender.
                </p>
                <h2 style={styles.subheading}>Quais organizações estão coletando as informações?</h2>
                <p style={styles.paragraph}>
                    Além da nossa coleta direta de informações, nossos fornecedores de serviços terceirizados (como empresas de cartão de
                    crédito, instituições de compensação e bancos) que podem fornecer serviços como crédito, seguros e serviços de
                    custódia podem coletar essas informações de nossos Visitantes e Clientes Autorizados. Não controlamos como esses
                    terceiros utilizam tais informações, mas solicitamos que eles divulguem como utilizam as informações pessoais fornecidas
                    a eles por Visitantes e Clientes Autorizados. Alguns desses terceiros podem ser intermediários que atuam apenas como
                    elos na cadeia de distribuição e não armazenam, retêm ou utilizam as informações fornecidas a eles.
                </p>
                <h2 style={styles.subheading}>Como o Site utiliza as Informações Pessoais Identificáveis?</h2>
                <p style={styles.paragraph}>
                    Utilizamos as Informações Pessoais Identificáveis para personalizar o Site, oferecer serviços adequados e atender
                    pedidos de compra e venda no Site. Podemos enviar e-mails para Visitantes e Clientes Autorizados sobre pesquisas ou
                    oportunidades de compra e venda no Site ou informações relacionadas ao tema do Site. Também podemos usar Informações
                    Pessoais Identificáveis para contatar Visitantes e Clientes Autorizados em resposta a consultas específicas ou para
                    fornecer informações solicitadas.
                </p>
                <h2 style={styles.subheading}>Com quem as informações podem ser compartilhadas?</h2>
                <p style={styles.paragraph}>
                    As Informações Pessoais Identificáveis dos Clientes Autorizados podem ser compartilhadas com outros Clientes Autorizados
                    que desejam avaliar possíveis transações. Podemos compartilhar informações agregadas sobre nossos Visitantes, incluindo
                    dados demográficos de Visitantes e Clientes Autorizados, com agências afiliadas e fornecedores terceirizados. Também
                    oferecemos a oportunidade de "opt-out", para que os usuários possam escolher não receber informações ou ser contatados
                    por nós ou por qualquer agência que atue em nosso nome.
                </p>
                <h2 style={styles.subheading}>Como as Informações Pessoais Identificáveis são armazenadas?</h2>
                <p style={styles.paragraph}>
                    As Informações Pessoais Identificáveis coletadas pelo aplicativo são armazenadas de forma segura e não são acessíveis a
                    terceiros ou funcionários da Buddy Software, exceto para uso conforme descrito acima.
                </p>
                <h2 style={styles.subheading}>Quais escolhas estão disponíveis para os Visitantes em relação à coleta, uso e distribuição das informações?</h2>
                <p style={styles.paragraph}>
                    Visitantes e Clientes Autorizados podem optar por não receber informações não solicitadas ou ser contatados por nós e/ou
                    nossos fornecedores e agências afiliadas respondendo aos e-mails conforme as instruções ou entrando em contato conosco
                    através de nosso site.
                </p>
                <h2 style={styles.subheading}>Cookies são utilizados no Site?</h2>
                <p style={styles.paragraph}>
                    Sim, cookies são usados por vários motivos. Utilizamos cookies para obter informações sobre as preferências de nossos
                    Visitantes e os serviços que eles selecionam. Também usamos cookies para fins de segurança, a fim de proteger nossos
                    Clientes Autorizados. Por exemplo, se um Cliente Autorizado estiver conectado e o site estiver inativo, o sistema pode
                    desconectá-lo automaticamente.
                </p>
                <h2 style={styles.subheading}>Como o aplicativo utiliza informações de login?</h2>
                <p style={styles.paragraph}>
                    O aplicativo utiliza informações de login, como endereços IP, ISPs e tipos de navegadores, para analisar tendências,
                    administrar o Site, rastrear o uso e movimentação dos usuários e coletar informações demográficas amplas.
                </p>
                <h2 style={styles.subheading}>Quais parceiros ou prestadores de serviços têm acesso a Informações Pessoais Identificáveis de Visitantes e/ou
                    Clientes Autorizados no Site?</h2>
                <p style={styles.paragraph}>
                    A Buddy Software firmou e continuará a firmar parcerias e afiliações com vários fornecedores. Esses fornecedores podem
                    ter acesso a certas Informações Pessoais Identificáveis em uma base de necessidade, para avaliar a elegibilidade dos
                    Clientes Autorizados para determinados serviços. Nossa política de privacidade não cobre a coleta ou uso dessas
                    informações por terceiros.
                </p>
                <h2 style={styles.subheading}>Como o Site mantém as Informações Pessoais Identificáveis seguras?</h2>
                <p style={styles.paragraph}>
                    Todos os nossos funcionários estão familiarizados com nossas políticas de segurança e práticas. As Informações Pessoais
                    Identificáveis de nossos Visitantes e Clientes Autorizados só são acessíveis por um número limitado de funcionários
                    qualificados que possuem uma senha para acessar as informações. Realizamos auditorias de nossos sistemas e processos de
                    segurança regularmente. Informações confidenciais, como números de cartão de crédito ou números de segurança social,
                    são protegidas por protocolos de criptografia para proteger as informações enviadas pela Internet.
                </p>
                <h2 style={styles.subheading}>Como os Visitantes podem corrigir qualquer imprecisão nas Informações Pessoais Identificáveis?</h2>
                <p style={styles.paragraph}>
                    Visitantes e Clientes Autorizados podem entrar em contato conosco para atualizar suas Informações Pessoais Identificáveis
                    ou corrigir qualquer imprecisão enviando um e-mail para contato@buddysoftware.com.br.
                </p>
                <h2 style={styles.subheading}>Um Visitante pode deletar ou desativar Informações Pessoais Identificáveis coletadas pelo Site?</h2>
                <p style={styles.paragraph}>
                    Fornecemos aos Visitantes e Clientes Autorizados um mecanismo para deletar/desativar as Informações Pessoais
                    Identificáveis entrando em contato conosco. No entanto, devido a backups e registros de exclusões, pode ser impossível
                    excluir totalmente as informações de um Visitante sem reter algumas informações residuais.
                </p>
                <h2 style={styles.subheading}>O que acontece se a Política de Privacidade mudar?</h2>
                <p style={styles.paragraph}>
                    Informaremos nossos Visitantes e Clientes Autorizados sobre mudanças em nossa política de privacidade publicando essas
                    alterações no Site.
                </p>
                <h2 style={styles.subheading}>Links</h2>
                <p style={styles.paragraph}>
                    O site <a href="https://buddysoftware.com.br/" style={styles.link}>https://buddysoftware.com.br/</a> pode conter links para outros sites. Não somos responsáveis
                    pelas práticas de privacidade ou pelo conteúdo de tais sites.
                </p>
                <h2 style={styles.subheading}>Contate-nos</h2>
                <p style={styles.paragraph}>
                    Se você tiver alguma dúvida sobre nossa política de privacidade, os procedimentos deste site ou suas interações com este
                    site, por favor, entre em contato conosco em contato@buddysoftware.com.br.
                </p>
            </div>
        </div>
    );
};

const styles = {
    container: {
        backgroundColor: '#ffffff', // Fundo branco
        padding: '20px',
        minHeight: '100vh'
    },
    content: {
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#ffffff', // Fundo branco para o conteúdo
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
    },
    heading: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#333'
    },
    subheading: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginTop: '20px',
        color: '#555'
    },
    paragraph: {
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#333'
    },
    link: {
        color: '#007bff',
        textDecoration: 'none'
    }
};

export default PrivacyPolicy;