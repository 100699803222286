import fast from '../assets/solusChat/svg/fast.svg';
import chat from '../assets/solusChat/svg/chat.svg';
import dashboard from '../assets/solusChat/svg/dashboard.svg';
import tags from '../assets/solusChat/svg/tags.svg';
import schedule from '../assets/solusChat/svg/schedule.svg';
import campaign from '../assets/solusChat/svg/campaign.svg';
import robot from '../assets/solusChat/svg/robot.svg';
import segments from '../assets/solusChat/svg/segments.svg';
import social from '../assets/solusChat/svg/social.svg';

interface FunctionalityProps {
  id: number;
  title: string;
  description: string;
  img: string;
}

export const functionality: FunctionalityProps[] = [
  {
    id: 8,
    title: 'Facebook e Instagram',
    description: 'Você pode realizar atendimentos diretamente por nossa plataforma integrando Facebook e Instagram, tornando a comunicação com seus clientes ainda mais fácil e eficiente.',
    img: social,
  },
  {
    id: 0,
    title: 'Atendimentos com IA',
    description: 'Revolucione seu serviço ao cliente com a inteligência artificial: desde respostas instantâneas personalizadas até análises preditivas, transforme cada interação em uma experiência excepcional.',
    img: robot,
  },
  {
    id: 1,
    title: 'Fluxos Segmentados por Setores',
    description:
      'Estruture e refine seus atendimentos com fluxos personalizados para cada setor, garantindo um serviço especializado e mais eficaz. As segmentações permitem organizar e categorizar atendimentos com precisão, melhorando a experiência do cliente e a eficiência da equipe.',
    img: segments,
  },
  {
    id: 2,
    title: 'Campanhas',
    description: 'Amplie seu alcance com campanhas direcionadas, permitindo o agendamento de mensagens para múltiplos contatos simultaneamente. Ideal para promover novidades, ofertas e informações importantes, elevando o engajamento e a satisfação do cliente.',
    img: campaign,
  },
  {
    id: 3,
    title: 'Dashboard',
    description:
      'Obtenha insights valiosos através de um dashboard detalhado, que oferece uma visão completa dos atendimentos, incluindo análises de desempenho e feedback de clientes.',
    img: dashboard,
  },
  {
    id: 4,
    title: 'Respostas Rápidas',
    description: 'Otimize seu tempo com comandos pré-configurados que agilizam cada atendimento, garantindo eficiência máxima.',
    img: fast,
  },
  {
    id: 5,
    title: 'Chat Interno',
    description:
      'Facilite a comunicação interna com um chat dedicado, melhorando a colaboração e a agilidade da equipe.',
    img: chat,
  },
  {
    id: 6,
    title: 'Etiquetas',
    description:
      'Simplifique a organização dos atendimentos com etiquetas personalizáveis, facilitando o acesso e a categorização das informações.',
    img: tags,
  },
  {
    id: 7,
    title: 'Agendamentos',
    description: 'Maximize o engajamento do cliente com mensagens programadas, enviando lembretes e promoções no momento certo. ',
    img: schedule,
  },
];
