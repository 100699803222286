interface QuestionProps {
  id: number;
  title: string;
  description: string;
}

export const frequencyQuestionsSolusChat: QuestionProps[] = [
  {
    id: 0,
    title: 'O número tem que ser WhatsApp Business?',
    description:
      'Não é necessário, o sistema funciona no WhatsApp normal e no WhatsApp Business.',
  },
  {
    id: 1,
    title:
      'Quanto tempo leva para que o sistema seja liberado após a contratação?',
    description: `Após a contratação através do nosso site você receberá uma mensagem do nosso robô de informações, contendo o link de pagamento.

Você possuirá duas opções de pagamento:
    
1) Pagamento via PIX – (PIX do boleto). A liberação ocorrerá em até 30 minutos após a confirmação pela instituição financeira.
    
2) Pagamento via código de barras. Pagando o boleto tradicional, a confirmação bancária poderá levar até três dias para confirmar, assim que o mesmo for confirmado pela instituição financeira, o sistema será liberado para utilização.`,
  },
];
