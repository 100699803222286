import React from 'react';

import {
  Container
} from './styles'

interface FunctionalityContainerProps {
  title: string;
  description: string;
  img: string;
}

export function FunctionalityContainer({ title, description, img }: FunctionalityContainerProps) {
  return (
    <Container
      initial={{ translateY: 100, opacity: 0 }}
      whileInView={{ translateY: 0, opacity: 1 }}
      viewport={{ once: true }}
      transition={{
        duration: 0.3
      }}
    >
      <img src={img} alt={title} />

      <h2>{title}</h2>
      <span>{description}</span>
    </Container>
  );
}