import React, { useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { mask, unmask } from 'remask';
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { v4 as uuid } from 'uuid';

import {
  Container,
  Content,
  Title,
  Form,
  Input,
  Button,
  Label,
  Selected,
  Select,
  SuccessDiv
} from './styles'
import { toast } from 'react-toastify';
import { PuffLoader } from 'react-spinners';
import axios from 'axios';

import { ThemeContext } from '../../../App';

interface PriceModalContactProps {
  onClose: () => void;
  plano: string;
  price: string;
  itens: Array<{
    included: 'included' | 'notIncluded';
    item: string;
  }>;
}

interface PlanProps {
  name: string,
  users: number,
  connections: number,
  queues: number,
  amount: string,
  useWhatsapp: boolean,
  useFacebook: boolean,
  useInstagram: boolean,
  useCampaigns: boolean,
  useSchedules: boolean,
  useInternalChat: boolean,
  useExternalApi: boolean,
  useKanban: boolean,
  useOpenAi: boolean,
  useIntegrations: boolean
}

interface CompanyProps {
  name: string,
  phone: string,
  email: string,
  document: string,
  planId: number,
  partnerId: string,
  password: string,
  dueDate: string
}

interface MessageProps {
  number: string,
  body: string,
  userId: string,
  queueId: string,
  sendSignature: boolean,
  closeTicket: boolean
}

export function PriceModalContact({ plano, onClose, itens, price }: PriceModalContactProps) {
  const [recaptcha, setRecaptcha] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [ddd, setDdd] = useState(48);
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { theme } = useContext(ThemeContext);

  const DDDs = [
    { "ID": 0, "DDD": 11, "Nome": "São Paulo" },
    { "ID": 1, "DDD": 12, "Nome": "São José dos Campos" },
    { "ID": 2, "DDD": 13, "Nome": "Santos" },
    { "ID": 3, "DDD": 14, "Nome": "Bauru" },
    { "ID": 4, "DDD": 15, "Nome": "Sorocaba" },
    { "ID": 5, "DDD": 16, "Nome": "Ribeirão Preto" },
    { "ID": 6, "DDD": 17, "Nome": "São José do Rio Preto" },
    { "ID": 7, "DDD": 18, "Nome": "Presidente Prudente" },
    { "ID": 8, "DDD": 19, "Nome": "Campinas" },
    { "ID": 9, "DDD": 21, "Nome": "Rio de Janeiro" },
    { "ID": 10, "DDD": 22, "Nome": "Campos dos Goytacazes" },
    { "ID": 11, "DDD": 24, "Nome": "Volta Redonda" },
    { "ID": 12, "DDD": 27, "Nome": "Vitória" },
    { "ID": 13, "DDD": 28, "Nome": "Cachoeiro de Itapemirim" },
    { "ID": 14, "DDD": 31, "Nome": "Belo Horizonte" },
    { "ID": 15, "DDD": 32, "Nome": "Juiz de Fora" },
    { "ID": 16, "DDD": 33, "Nome": "Governador Valadares" },
    { "ID": 17, "DDD": 34, "Nome": "Uberlândia" },
    { "ID": 18, "DDD": 35, "Nome": "Poços de Caldas" },
    { "ID": 19, "DDD": 37, "Nome": "Divinópolis" },
    { "ID": 20, "DDD": 38, "Nome": "Montes Claros" },
    { "ID": 21, "DDD": 41, "Nome": "Curitiba" },
    { "ID": 22, "DDD": 42, "Nome": "Ponta Grossa" },
    { "ID": 23, "DDD": 43, "Nome": "Londrina" },
    { "ID": 24, "DDD": 44, "Nome": "Maringá" },
    { "ID": 25, "DDD": 45, "Nome": "Foz do Iguaçu" },
    { "ID": 26, "DDD": 46, "Nome": "Francisco Beltrão" },
    { "ID": 27, "DDD": 47, "Nome": "Joinville" },
    { "ID": 28, "DDD": 48, "Nome": "Florianópolis" },
    { "ID": 29, "DDD": 49, "Nome": "Chapecó" },
    { "ID": 30, "DDD": 51, "Nome": "Porto Alegre" },
    { "ID": 31, "DDD": 53, "Nome": "Pelotas" },
    { "ID": 32, "DDD": 54, "Nome": "Caxias do Sul" },
    { "ID": 33, "DDD": 55, "Nome": "Santa Maria" },
    { "ID": 34, "DDD": 61, "Nome": "Brasília" },
    { "ID": 35, "DDD": 62, "Nome": "Goiânia" },
    { "ID": 36, "DDD": 63, "Nome": "Palmas" },
    { "ID": 37, "DDD": 64, "Nome": "Rio Verde" },
    { "ID": 38, "DDD": 65, "Nome": "Cuiabá" },
    { "ID": 39, "DDD": 66, "Nome": "Rondonópolis" },
    { "ID": 40, "DDD": 67, "Nome": "Campo Grande" },
    { "ID": 41, "DDD": 68, "Nome": "Rio Branco" },
    { "ID": 42, "DDD": 69, "Nome": "Porto Velho" },
    { "ID": 43, "DDD": 71, "Nome": "Salvador" },
    { "ID": 44, "DDD": 73, "Nome": "Ilhéus" },
    { "ID": 45, "DDD": 74, "Nome": "Juazeiro" },
    { "ID": 46, "DDD": 75, "Nome": "Feira de Santana" },
    { "ID": 47, "DDD": 77, "Nome": "Barreiras" },
    { "ID": 48, "DDD": 79, "Nome": "Aracaju" },
    { "ID": 49, "DDD": 81, "Nome": "Recife" },
    { "ID": 50, "DDD": 82, "Nome": "Maceió" },
    { "ID": 51, "DDD": 83, "Nome": "João Pessoa" },
    { "ID": 52, "DDD": 84, "Nome": "Natal" },
    { "ID": 53, "DDD": 85, "Nome": "Fortaleza" },
    { "ID": 54, "DDD": 86, "Nome": "Teresina" },
    { "ID": 55, "DDD": 87, "Nome": "Petrolina" },
    { "ID": 56, "DDD": 88, "Nome": "Juazeiro do Norte" },
    { "ID": 57, "DDD": 89, "Nome": "Picos" },
    { "ID": 58, "DDD": 91, "Nome": "Belém" },
    { "ID": 59, "DDD": 92, "Nome": "Manaus" },
    { "ID": 60, "DDD": 93, "Nome": "Santarém" },
    { "ID": 61, "DDD": 94, "Nome": "Marabá" },
    { "ID": 62, "DDD": 95, "Nome": "Boa Vista" },
    { "ID": 63, "DDD": 96, "Nome": "Macapá" },
    { "ID": 64, "DDD": 97, "Nome": "Coari" },
    { "ID": 65, "DDD": 98, "Nome": "São Luís" },
    { "ID": 66, "DDD": 99, "Nome": "Imperatriz" }
  ];

  async function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!recaptcha) {
      toast.error('Por favor, confirme que você não é um robô');
      return;
    }
    if (!name) {
      toast.error('Por favor, preencha o campo nome');
      return;
    };

    if (!email) {
      toast.error('Por favor, preencha o campo email');
      return;
    }

    if (!regexEmail.test(email)) {
      toast.error('Por favor, preencha o campo email corretamente');
      return;
    }

    if (!phone) {
      toast.error('Por favor, preencha o campo telefone');
      return;
    }

    if (phone.length < 10) {
      toast.error('Por favor, preencha o campo telefone corretamente');
      return;
    }

    setSending(true);

    try {
      const responseToken = await axios.post(`/auth/login`,
        {
          email: process.env.REACT_APP_L_U,
          password: process.env.REACT_APP_L_P
        },
      );

      // const responsePlan = await axios.post(`${api}/plans`, {
      //   name: name.replaceAll(' ', '_'),
      //   users: Number(itens[0].item.replace(/\D/g, '')),
      //   connections: Number(itens[1].item.replace(/\D/g, '')),
      //   queues: Number(itens[2].item.replace(/\D/g, '')),
      //   amount: price,
      //   useWhatsapp: itens[3].included === 'included' ? true : false,
      //   useFacebook: itens[3].included === 'included' ? true : false,
      //   useInstagram: itens[3].included === 'included' ? true : false,
      //   useCampaigns: itens[4].included === 'included' ? true : false,
      //   useSchedules: itens[5].included === 'included' ? true : false,
      //   useInternalChat: itens[6].included === 'included' ? true : false,
      //   useExternalApi: itens[7].included === 'included' ? true : false,
      //   useKanban: itens[8].included === 'included' ? true : false,
      //   useOpenAi: itens[10].included === 'included' ? true : false,
      //   useIntegrations: itens[9].included === 'included' ? true : false
      // } as PlanProps, {
      //   headers: {
      //     'Authorization': `Bearer ${responseToken.data.token}`
      //   }
      // });

      let currentDate = new Date();
      let futureDate = new Date(currentDate);
      futureDate.setDate(currentDate.getDate() + Number(process.env.REACT_APP_PERIOT_TEST));
      let dueDate = futureDate.toISOString();

      const senha = uuid().replace(/-/g, '').slice(0, 12);

      await axios.post(`/companies`, {
        name,
        phone: `${ddd}${unmask(phone)}`,
        email,
        document: '',
        planId: plano === 'Basic' ? 28 : plano === 'Essential' ? 29 : 30,
        partnerId: '1',
        password: senha,
        dueDate: dueDate
      } as CompanyProps, {
        headers: {
          'Authorization': `Bearer ${responseToken.data.token}`
        }
      });

      const msg = `🎉 Conta criada com sucesso! 

👤 Usuário: ${email}
🔑 Senha: ${senha}

✨Acesse o Soluschat pelo link abaixo:
👉 https://app.soluschat.com.br/ 🚀

Bem-vindo ao Soluschat! Estamos felizes em tê-lo conosco. 😊`

      await axios.post(`/api/messages/send`, {
        number: `${ddd}${unmask(phone)}`,
        body: msg,
        userId: '',
        queueId: '20',
        sendSignature: false,
        closeTicket: false
      } as MessageProps, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_BOT_T}`,
          'X-Requested-With': 'XMLHttpRequest'
        }
      });

      const numerosArray = process.env.REACT_APP_NUMBER ? process.env.REACT_APP_NUMBER.split(',') : [];

      for (const number of numerosArray) {
        const msg = `Cadastrado um novo cliente pelo site no Soluschat com *${process.env.REACT_APP_PERIOT_TEST}* dias de teste no sistema.

*Dados do cliente*:
Nome: ${name}
Número: ${ddd}${unmask(phone)}
Usuário: ${email}
Senha: ${senha}
Empresa: ${name}
Plano: ${plano}
Data do cadastro: ${new Date().toLocaleString()}`;

        await axios.post(`/api/messages/send`, {
          number: number,
          body: msg,
          userId: '',
          queueId: '20',
          sendSignature: false,
          closeTicket: false
        } as MessageProps, {
          headers: {
            'Authorization': `Bearer ${process.env.REACT_APP_BOT_T}`,
            'X-Requested-With': 'XMLHttpRequest'
          }
        });
      }

      toast.success('Contrato enviada com sucesso!');
      setSuccess(true);
    } catch (error: any) {
      console.error('Erro ao fazer a requisição POST:', error);
      if (error.response && error.response.data) {
        const responseData = error.response.data;
        if (typeof responseData === 'string' && responseData.includes('UniqueConstraintError')) {
          toast.error('Email ou nome já cadastrados, tente novamente com outro email ou nome');
        }
        else if (typeof responseData === 'object' && responseData.message && responseData.message.includes('UniqueConstraintError')) {
          toast.error('Email ou nome já cadastrados, tente novamente com outro email ou nome');
        }
        else {
          toast.error('Erro ao enviar contrato, tente novamente mais tarde');
        }
      } else {
        toast.error('Erro ao enviar contrato, tente novamente mais tarde');
      }
      return;
    } finally {
      setSending(false);
    }
  }

  return (
    <Container>
      {success ?
        <Content>
          <Title style={{ justifyContent: 'center' }}>Conta criada com sucesso</Title>
          <SuccessDiv>
            <p>Você receberá suas informações via Whatsapp e foi concedido a você período de testes de 7 dias grátis.</p>
            <a href="https://app.soluschat.com.br/" target="_blank" rel="noreferrer">Clique aqui para acessar o sistema</a>
            <FaCheckCircle className='success' />
            <Button onClick={onClose}>
              Fechar
            </Button>
          </SuccessDiv>
        </Content> :
        <Content>
          <Title>Preencha os campos <span onClick={onClose}>X</span></Title>
          <Form onSubmit={handleCreate}>
            <Label htmlFor='name'>Nome completo<span>*</span></Label>
            <Input value={name} onChange={(e) => setName(e.target.value)} id='name' />
            <Label htmlFor='email'>Email<span>*</span></Label>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} id='email' type='email' />
            <Label htmlFor='phone'>(DDD) Telefone<span>*</span></Label>
            <Select>
              <MdKeyboardArrowDown className='arrow' />
              <select onChange={(e) => setDdd(Number(e.target.value))} value={ddd}>
                {DDDs.map(ddd => <option key={ddd.ID} value={ddd.DDD}>{ddd.DDD}</option>)}
              </select>
              <Input value={phone} onChange={(e) => setPhone(mask(e.target.value, ['99999-9999']))} style={{ width: '100%' }} id='phone' />
            </Select>
            <Selected>
              <h2>Plano selecionado</h2>
              <p>{plano}</p>
            </Selected>

            <ReCAPTCHA sitekey={`${process.env.REACT_APP_GR}`}
              onChange={() => setRecaptcha(true)}
              style={{ display: 'flex', justifyContent: 'center', margin: '2rem auto' }}
              theme={theme}
            />

            <Button disabled={!recaptcha || sending}>
              {sending ? <PuffLoader color='#fff' size={30} /> : 'Enviar'}
            </Button>
          </Form>
        </Content>
      }
    </Container>
  );
}