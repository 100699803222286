import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    height: 15rem;
    background: rgba(0,0,0,0.8);
    border-top: 2px solid var(--primarySolusChat);
    display: flex;
    flex-direction: column;
    z-index: 1000;
`;

export const Top = styled.div`
    display: flex;
    width: 100%;
    margin-top: -1rem;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--dark);
    
    svg {
        position: absolute;
        right: 5rem;
        font-size: 2rem;
        color: #000000;
        cursor: pointer;
        background: var(--primarySolusChat);
        border-radius: 1rem;

        @media screen and (max-width: 630px) {
            right: 1rem;
        }
    }
    `;

export const New = styled.div`
    padding: 0.2rem 1rem;
    background: var(--primarySolusChat);
    border-radius: 1rem;
    font-size: 1.5rem;
    `;

export const Description = styled.span`
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    margin: auto 0;
`;

export const SolusChat = styled.span`
    display: flex;
    margin: 0.5rem;
    color: var(--primarySolusChat);
`;

export const Tag = styled.span`
    display: flex;
    margin: 0.5rem;
    padding: 0 0.5rem;
    border-radius: 0.5rem;
    background: var(--instagram);
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    svg {
        font-size: 1.5rem;
    }
`;

export const More = styled.div`
    position: absolute;
    color: #ffffff;
    bottom: 1rem;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;

    span {
        display: flex;
        flex-direction: column;
        align-items:center;
        cursor: pointer;
        font-size: 1rem;
        width: fit-content;

        svg {
            margin-top: -0.5rem;
        }
    }
`;

export const Modal = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    background: rgba(0,0,0,0.9);
    padding: 1rem;
`;

export const Content = styled.div`
    border-radius: 0.5rem;
    background: var(--dark);
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
    position: relative;
    display: flex;
    white-space:  pre-wrap;
    padding: 2rem;
    overflow: auto;

    svg {
        font-size: 1.5rem;
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
    }

    span {
        font-size: 1rem;

    }
`;