import { motion } from 'framer-motion';
import styled from 'styled-components';

interface BackgroundProps {
  top: boolean;
  theme: string;
}

export const Container = styled(motion.section)`
  position: relative;
`;

export const Background = styled.div`
  background-color: #363636;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='p' width='100' height='100' patternUnits='userSpaceOnUse'%3E%3Cpath data-color='outline' fill='none' stroke='%23FFFFFF' stroke-width='0.25' d='M50 0v100M100 50H0'%3E%3C/path%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23p)' width='100%25' height='100%25'%3E%3C/rect%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.2;
`;

export const Gradient = styled.div<BackgroundProps>`
  position: absolute;
  top: ${({ top }) => top && '0'};
  bottom: ${({ top }) => !top && '0'};
  transform: ${({ top }) => !top && 'rotate(180deg)'};
  width: 100%;
  height: 15rem;
  background: ${({ theme }) => theme == 'dark' ? 'linear-gradient(180deg, rgba(26, 26, 26, 1) 30%, rgba(26, 26, 26, 0) 100%)' :
    'linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(26, 26, 26, 0) 100%)'};
  z-index: 2;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-wrap: wrap;

  @media screen and (max-width: 728px) {
    flex-direction: column;
  }
  z-index: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  position: relative;
  padding: 0 5rem 0 10rem;
  height: 30rem;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 600px) {
    padding: 0 2rem;
  }

  @media screen and (max-width: 1138px) {
    margin-top: 10rem;
  }
`;

export const Border = styled.div`
  position: absolute;
  width: 1rem;
  height: 100%;
  background: var(--primarySolusChat);
  border-radius: 1rem;
  left: 0;
  top: 0;
  bottom: 0;
  box-shadow: 0px 0px 100px 30px rgb(104, 244, 143, 0.5);
  filter: brightness(1.2);

  @media screen and (max-width: 728px) {
    height: 80%;
  }
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 5rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }
`;

export const Description = styled.div`
  text-align: left;
  padding: 1rem 0;
  white-space: pre-wrap;

  span {
    color: var(--primarySolusChat);
    font-weight: bold;
    font-size: 1.3rem;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  position: relative;
  flex: 1;

  img {
    display: block;
    width: 100%;
    height: auto;
    max-height: 35rem;
    z-index: 1;
    transform: rotateY(180deg);

    min-width: 20rem;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
