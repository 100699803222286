import React, { useContext } from 'react';

import Buddy from '../../../assets/logo.png';
import SolusChat from '../../../assets/solusChat/SolusChatLogoLongo.svg';
import SolusChatDark from '../../../assets/solusChat/SolusChatLogoLongoDark.svg';

import { ThemeContext } from '../../../App';

import {
  Container,
  Slide
} from './styles'

export function InfiniteLogos() {
  const timeToRepeat = 11;
  const { theme } = useContext(ThemeContext);

  return (
    <Container>
      {[...Array(timeToRepeat)].map((_, index) => (
        <Slide key={index}>
          <img src={Buddy} alt="buddy" className='buddy' />
          <img src={theme == 'dark' ? SolusChatDark : SolusChat} alt="solusChat" className='soluschat' />
        </Slide>
      ))}
    </Container>
  );
}