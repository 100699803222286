import React from 'react';
import Pdf from '../../assets/Apresentacao_Comercial_SolusChat.pdf';
import { FaArrowRight } from "react-icons/fa6";

import { Container, Embed, Site } from './styles';

export function PropostaComercial() {
  return (
    <Container>
      <h1>Proposta Comercial</h1>
      <Site to='/'>Ir para o site <FaArrowRight /></Site>
      <Embed data={Pdf}><a href={Pdf} download="PropostaComercial.pdf">Ver PDF</a></Embed>
    </Container>
  );
}
