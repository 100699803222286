import React, { useEffect } from 'react';

const TERMS_URL = 'https://buddysoftware.com.br/';

const TermsOfUse = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div style={styles.container}>
            <div style={styles.content}>
                <h1 style={styles.heading}>Termos de Uso da Buddy Software</h1>
                <p style={styles.paragraph}>
                    AO ACESSAR OU UTILIZAR <a href={TERMS_URL} style={styles.link}>{TERMS_URL}</a>, VOCÊ DECLARA QUE TEM PLENA AUTORIDADE PARA AGIR EM SEU NOME OU EM NOME DE TERCEIROS, EMPRESAS OU ENTIDADES LEGAIS, E QUE O SEU USO OU INTERAÇÃO, BEM COMO A CONTINUIDADE DO USO OU INTERAÇÃO COM O SITE, CONSTITUI A SUA LEITURA E ACEITAÇÃO DESTES TERMOS DE USO, BEM COMO OUTROS ACORDOS QUE POSSAMOS PUBLICAR NO SITE.
                </p>
                <p style={styles.paragraph}>
                    AO VISUALIZAR, VISITAR, UTILIZAR OU INTERAGIR COM <a href={TERMS_URL} style={styles.link}>{TERMS_URL}</a> OU QUALQUER BANNER, POP-UP OU PUBLICIDADE EXIBIDA NO SITE, VOCÊ ESTÁ CONCORDANDO COM TODAS AS DISPOSIÇÕES DESTES TERMOS DE USO E COM A POLÍTICA DE PRIVACIDADE DE <a href={TERMS_URL} style={styles.link}>{TERMS_URL}</a>.
                </p>
                <p style={styles.paragraph}>
                    {TERMS_URL} NEGA ESPECIFICAMENTE O ACESSO A QUALQUER INDIVÍDUO QUE SEJA ABRANGIDO PELO ATO DE PROTEÇÃO À PRIVACIDADE ONLINE DAS CRIANÇAS (COPPA) DE 1998.
                </p>
                <p style={styles.paragraph}>
                    {TERMS_URL} RESERVA-SE O DIREITO DE NEGAR O ACESSO A QUALQUER PESSOA OU VISITANTE POR QUALQUER MOTIVO LEGAL. DE ACORDO COM OS TERMOS DA POLÍTICA DE PRIVACIDADE, QUE VOCÊ ACEITA COMO CONDIÇÃO PARA VISUALIZAR O SITE, {TERMS_URL} TEM PERMISSÃO PARA COLETAR E ARMAZENAR DADOS E INFORMAÇÕES PARA O PROPÓSITO DE EXCLUSÃO E PARA OUTRAS FINALIDADES.
                </p>
                <p style={styles.paragraph}>
                    ESTE ACORDO DE TERMOS DE USO PODE SER ALTERADO DE TEMPOS EM TEMPOS. OS VISITANTES TÊM O DEVER AFIRMATIVO, COMO PARTE DA CONSIDERAÇÃO PARA OBTER PERMISSÃO DE ACESSAR {TERMS_URL}, DE MANTEREM-SE INFORMADOS SOBRE TAIS ALTERAÇÕES REVENDO ESTA PÁGINA DE TERMOS DE USO CADA VEZ QUE VISITAREM O SITE.
                </p>
                <h2 style={styles.subheading}>PARTES DO ACORDO DE TERMOS DE USO</h2>
                <p style={styles.paragraph}>
                    Visitantes, visualizadores, usuários, assinantes, membros, afiliados ou clientes, coletivamente referidos como "Visitantes", são partes deste acordo. O site e seus proprietários e/ou operadores também são partes deste acordo, referidos como "Website".
                </p>
                <h2 style={styles.subheading}>USO DE INFORMAÇÕES DESTE WEBSITE</h2>
                <p style={styles.paragraph}>
                    A menos que tenha um contrato escrito expresso em contrário com este site, visitantes, visualizadores, assinantes, membros, afiliados ou clientes não têm direito de usar as informações deste site em um ambiente comercial ou público; não têm o direito de transmiti-las, copiá-las, salvá-las, imprimi-las, vendê-las ou publicar qualquer parte do conteúdo deste site.
                </p>
                <h2 style={styles.subheading}>PROPRIEDADE DO WEBSITE OU DIREITO DE USO, VENDA, PUBLICAÇÃO DE CONTEÚDOS</h2>
                <p style={styles.paragraph}>
                    O site e seus conteúdos são de propriedade ou licenciados pelo proprietário do site. O material contido no site deve ser presumido como sendo proprietário e protegido por direitos autorais. Os visitantes não têm direitos sobre o conteúdo do site. O uso do conteúdo do site por qualquer motivo é ilegal, a menos que seja feito mediante contrato expresso ou permissão do proprietário do site.
                </p>
                <h2 style={styles.subheading}>PROIBIÇÃO DE HIPERLINKS, CO-BRANDING, “FRAMING” E REFERÊNCIAS AO SITE</h2>
                <p style={styles.paragraph}>
                    A menos que expressamente autorizado, ninguém pode criar um hyperlink para <a href={TERMS_URL} style={styles.link}>{TERMS_URL}</a> ou partes do site por qualquer motivo. Além disso, não é permitido referenciar a URL do site em qualquer mídia comercial ou não comercial sem permissão expressa.
                </p>
                <h2 style={styles.subheading}>ISENÇÃO DE RESPONSABILIDADE PELO CONTEÚDO DO SITE</h2>
                <p style={styles.paragraph}>
                    {TERMS_URL} isenta-se de qualquer responsabilidade pela precisão do conteúdo exibido, vinculado ou mencionado no site. Os visitantes assumem todos os riscos ao visualizar, ler ou utilizar essas informações.
                </p>
                <h2 style={styles.subheading}>LIMITAÇÃO DE RESPONSABILIDADE</h2>
                <p style={styles.paragraph}>
                    Ao visualizar ou interagir com {TERMS_URL}, incluindo anúncios ou downloads, o visitante renuncia a quaisquer reclamações de danos de qualquer natureza.
                </p>
                <p style={styles.paragraph}>
                    Para mais detalhes, acesse <a href={TERMS_URL} style={styles.link}>{TERMS_URL}</a>.
                </p>
            </div>
        </div>
    );
};

// Estilos
const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        minHeight: '100vh',
        backgroundColor: '#f4f4f4',
    },
    content: {
        maxWidth: '800px',
        width: '100%',
        padding: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        borderRadius: '8px',
    },
    heading: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    subheading: {
        marginTop: '20px',
        marginBottom: '10px',
        color: '#333',
    },
    paragraph: {
        lineHeight: '1.6',
        marginBottom: '15px',
        color: '#555',
    },
    link: {
        color: '#007bff',
        textDecoration: 'none',
    },
};

export default TermsOfUse;