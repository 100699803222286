import Basic from '../assets/basic.svg';
import Essential from '../assets/essential.svg';
import Prime from '../assets/prime.svg';

interface PriceProps {
  type: string;
  img: string;
  price: string;
  invoicing: 'mês' | 'ano';
  link: string;
  itens: Array<{
    id: number;
    included: 'included' | 'notIncluded';
    item: string;
  }>;
  popular: boolean;
}

export const prices: PriceProps[] = [
  {
    type: 'Basic',
    img: Basic,
    price: '189,90',
    invoicing: 'mês',
    popular: false,
    link: 'https://api.whatsapp.com/send?phone=5548988791489&text=Ol%C3%A1,%20me%20interessei%20em%20comprar%20o%20produto%20Basic%20do%20SolusChat%20',
    itens: [
      {
        id: 0,
        item: 'Usuários: 4',
        included: 'included',
      },
      {
        id: 1,
        item: 'Conexões: 1',
        included: 'included',
      },
      {
        id: 2,
        item: 'Filas: 4',
        included: 'included',
      },
      {
        id: 3,
        item: 'Whatsapp',
        included: 'included',
      },
      {
        id: 4,
        item: 'Campanhas',
        included: 'notIncluded',
      },
      {
        id: 5,
        item: 'Agendamentos',
        included: 'included',
      },
      {
        id: 6,
        item: 'Chat Interno',
        included: 'included',
      },
      {
        id: 7,
        item: 'Api Externa',
        included: 'included',
      },
      {
        id: 8,
        item: 'Kanban',
        included: 'notIncluded',
      },
      {
        id: 9,
        item: 'Integrações com N8N, Typebot, etc...',
        included: 'notIncluded'
      },
      {
        id: 10,
        item: 'Integrações com IA(consulte contabilização de tokens)',
        included: 'notIncluded'
      }
    ],
  },
  {
    type: 'Essential',
    img: Essential,
    price: '279,90',
    invoicing: 'mês',
    popular: true,
    link: 'https://api.whatsapp.com/send?phone=5548988791489&text=Ol%C3%A1,%20me%20interessei%20em%20comprar%20o%20produto%20Essential%20do%20SolusChat%20',
    itens: [
      {
        id: 0,
        item: 'Usuários: 12',
        included: 'included',
      },
      {
        id: 1,
        item: 'Conexões: 1',
        included: 'included',
      },
      {
        id: 2,
        item: 'Filas: 12',
        included: 'included',
      },
      {
        id: 3,
        item: 'Whatsapp',
        included: 'included',
      },
      {
        id: 4,
        item: 'Campanhas',
        included: 'notIncluded',
      },
      {
        id: 5,
        item: 'Agendamentos',
        included: 'included',
      },
      {
        id: 6,
        item: 'Chat Interno',
        included: 'included',
      },
      {
        id: 7,
        item: 'Api Externa',
        included: 'included',
      },
      {
        id: 8,
        item: 'Kanban',
        included: 'included',
      },
      {
        id: 9,
        item: 'Integrações com N8N, Typebot, etc...',
        included: 'included'
      },
      {
        id: 10,
        item: 'Integrações com IA(consulte contabilização de tokens)',
        included: 'notIncluded'
      }
    ],
  },
  {
    type: 'Prime',
    img: Prime,
    price: '349,90',
    invoicing: 'mês',
    popular: false,
    link: 'https://api.whatsapp.com/send?phone=5548988791489&text=Ol%C3%A1,%20me%20interessei%20em%20comprar%20o%20produto%20Prime%20do%20SolusChat%20',
    itens: [
      {
        id: 0,
        item: 'Usuários: 20',
        included: 'included',
      },
      {
        id: 1,
        item: 'Conexões: 2',
        included: 'included',
      },
      {
        id: 2,
        item: 'Filas: 20',
        included: 'included',
      },
      {
        id: 3,
        item: 'Whatsapp',
        included: 'included',
      },
      {
        id: 4,
        item: 'Campanhas',
        included: 'included',
      },
      {
        id: 5,
        item: 'Agendamentos',
        included: 'included',
      },
      {
        id: 6,
        item: 'Chat Interno',
        included: 'included',
      },
      {
        id: 7,
        item: 'Api Externa',
        included: 'included',
      },
      {
        id: 8,
        item: 'Kanban',
        included: 'included',
      },
      {
        id: 9,
        item: 'Integrações com N8N, Typebot, etc...',
        included: 'included'
      },
      {
        id: 10,
        item: 'Integrações com IA (consulte contabilização de tokens)',
        included: 'included'
      }
    ],
  },
];
