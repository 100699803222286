import React from 'react';

import { frequencyQuestionsSolusChat } from '../../../../utils/faqSolusChat';
import { FaqContainer } from '../../../FaqContainer';

import { MdEmail } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

import { Container, Content, Title, LeftSide, RightSide, Subtitle, Buttons } from './styles';
import { Button } from '../../../Button';

import { Faq } from '../../../../utils/texts';

export function FaqSolusChat() {
  return (
    <Container id='helpsoluschat'>
      <Content>
        <LeftSide>
          <Title>
            {Faq.title}
          </Title>
          <Subtitle>
            {Faq.description}
          </Subtitle>
          <Buttons>
            <Button icon={<MdEmail />} site='SolusChat' onClick={() => window.open('mailto:comercial@buddysoftware.com.br?subject=&body=')}>E-mail</Button>
            <a href="https://api.whatsapp.com/send?phone=5548988791489&text=Ol%C3%A1,%20tenho%20uma%20dúvida%20sobre%20o%20SolusChat%20" target="_blank" rel="noreferrer">
              <Button icon={<IoLogoWhatsapp />} site='SolusChat'>Whatsapp</Button>
            </a>
          </Buttons>
        </LeftSide>
        <RightSide>
          {frequencyQuestionsSolusChat.map((question) => <FaqContainer key={question.id} title={question.title} description={question.description} site='SolusChat' />)}
        </RightSide>
      </Content>
    </Container>
  );
}
