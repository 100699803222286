import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;

  img {
    width: 40%;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    img {
      width: 100%;
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    color: var(--primarySolusChat);
  }
`;
