import React, { useEffect } from 'react';

import { Container } from './styles';
import { HomeSolusChat } from '../../components/SolusChat/sections/HomeSolusChat';
import { HeaderSolusChat } from '../../components/SolusChat/HeaderSolusChat';
import { InfiniteLogos } from '../../components/SolusChat/InfiniteLogos';
import { SummarySolusChat } from '../../components/SolusChat/sections/SummarySolusChat';
import { FunctionalitySolusChat } from '../../components/SolusChat/sections/FunctionalitySolusChat';
import { Price } from '../../components/SolusChat/sections/Price';
import { FaqSolusChat } from '../../components/SolusChat/sections/FaqSolusChat';
import { Bottom } from '../../components/Bottom';
import { FooterSolusChat } from '../../components/SolusChat/sections/FooterSolusChat';
import { News } from '../../components/SolusChat/News';

export function LandingPageSolusChat() {
  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = 'SolusChat';
  }, []);

  return (
    <Container>
      <HeaderSolusChat />
      <HomeSolusChat />
      <InfiniteLogos />
      <SummarySolusChat />
      <FunctionalitySolusChat />
      <Price />
      <FaqSolusChat />
      <FooterSolusChat />
      <Bottom site='SolusChat' />
      <News />
    </Container>
  );
}
