import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  overflow: hidden;

  @media screen and (max-width: 728px) {
    flex-direction: column;
  }

  img {
    height: 3rem;
  }
`;
