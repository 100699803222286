import styled from 'styled-components';
import CardBackground from '../../../../assets/solusChat/cardBackground.png';

interface BackgroundProps {
  top: boolean;
  theme: string;
}

export const Container = styled.section`
  position: relative;
`;

export const Background = styled.div`
  background-color: #1a1a1a;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='p' width='100' height='100' patternUnits='userSpaceOnUse'%3E%3Cpath data-color='outline' fill='none' stroke='%23FFFFFF' stroke-width='0.25' d='M50 0v100M100 50H0'%3E%3C/path%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23p)' width='100%25' height='100%25'%3E%3C/rect%3E%3C/svg%3E");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.1;
`;

export const Gradient = styled.div<BackgroundProps>`
  position: absolute;
  top: ${({ top }) => top && '0'};
  bottom: ${({ top }) => !top && '0'};
  transform: ${({ top }) => !top && 'rotate(180deg)'};
  width: 100%;
  height: 15rem;

  background: ${({ theme }) => theme == 'dark' ? 'rgb(26, 26, 26)' : 'rgb(255, 255, 255)'};
  background: ${({ theme }) => theme == 'dark' ? 'linear-gradient(180deg, rgba(26, 26, 26, 1) 20%, rgba(26, 26, 26, 0) 100%)' : 'linear-gradient(180deg, rgba(255, 255, 255, 1) 20%, rgba(26, 26, 26, 0) 100%)'};
  z-index: 2;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 10rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: 728px) {
    flex-direction: column;
  }
  z-index: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  position: relative;
  height: 30rem;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  @media screen and (max-width: 600px) {
    padding: 0 2rem;
  }
`;

export const Title = styled.div`
  h1 {
    text-align: left;
    font-size: 4rem;
  }

  span {
    font-size: 4rem;
    color: var(--primarySolusChat);
  }

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 2.5rem;

    span {
      font-size: 3rem;
    }
  }
`;

export const Description = styled.span`
  text-align: left;
  padding: 1rem 0;
  font-size: 1.1rem;
  white-space: pre-wrap;
  color: #ffffff;

`;

export const DescriptionContainer = styled.div`
  flex: 1;
  background-image: url(${CardBackground});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
  border-radius: 2rem;
  filter: drop-shadow(5px 5px 0px var(--primarySolusChat));
  z-index: 1;
`;
