import styled from 'styled-components';

export const Container = styled.section`
  position: relative;
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 1400px;
  padding: 7rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-wrap: wrap;
  gap: 2rem;
  flex-direction: column;

  @media screen and (max-width: 728px) {
    flex-direction: column;
  }
  z-index: 1;
`;

export const Title = styled.h1`
  text-align: left;
  font-size: 4rem;

  @media screen and (max-width: 800px) {
    font-size: 3rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 2.5rem;
  }
`;

export const Description = styled.span`
  text-align: left;
  padding: 1rem 0;
`;

export const GridContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  gap: 10rem 5rem;
  justify-content: center;
  margin-top: 3rem;
`;
