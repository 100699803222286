import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 1.5rem 1.5rem;
  width: 20rem;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 0px var(--primarySolusChat));
  border: 1px solid var(--primarySolusChat);
  background: var(--darkSolusChat);
`;

export const Title = styled.h2`
  align-self: center;
  margin-bottom: 0.5rem;
`;

export const Img = styled.img`
  align-self: center;
  width: 100%;
  height: 12rem;
`;

export const Price = styled.div`
  margin: 0.5rem 0;

  span {
    color: var(--primarySolusChat);
    letter-spacing: 2px;
    font-weight: bold;
  }
`;

export const Invoicing = styled.div`
  font-size: 0.85rem;
`;

export const ItemsContainer = styled.div`
  border-top: 1px solid var(--light);
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  flex: 1;
`;

export const Items = styled.div``;

export const ItemsTitle = styled.h4`
  padding: 0.5rem 0;
`;

export const Item = styled.div`
  display: flex;
  padding: 0.3rem 0;

  svg {
    width: 2rem;
    margin-right: 0.5rem;

    &.included {
      color: var(--primarySolusChat);
      filter: brightness(1.3);
    }

    &.notIncluded {
      filter: brightness(0.2);
    }
  }
`;

export const Resume = styled.div`
  font-size: 0.9rem;
  width: 15rem;
`;

export const PrinceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  border: none;
  width: 10rem;
  align-self: center;
  background: var(--primarySolusChat);
  border-radius: 0.5rem;
  font-weight: bold;
  gap: 0.5rem;
  padding: 0.5rem;
  color: var(--dark);

  &:hover {
    filter: brightness(0.8);
  }
`;

export const Popular = styled.div`
  position: absolute;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: var(--primarySolusChat);
  display: flex;
  align-items: center;
  justify-content: center;
  top: -1rem;
  left: -1rem;
  color: var(--darkSolusChat);
`;
